import { DeprecatedLink } from "@resource/atlas/link/_deprecated/DeprecatedLink";
import { useToast } from "@resource/atlas/toast/use-toast";
import { ErrorCodes } from "@resource/common";
import clsx from "clsx";
import { DevLoginSection } from "components/LoginForm/DevLoginSection";
import LoginForm from "components/LoginForm/LoginForm";
import { gql } from "generated/graphql-codegen";
import guideLogoDark from "icons/guide-logo-horizontal--color-dark.svg";
import Head from "next/head";
import Image from "next/legacy/image";
import NextLink from "next/link";
import { useRouter } from "next/router";
import heroIllustration from "public/images/hero-illustration.png";
import { useEffect } from "react";
import {
  LOGIN_COMMUNICATION_CHANNEL,
  postMessageAcrossTabs,
  useAcrossTabsListener,
} from "utils/acrossTabsMessaging";
import inIframe from "utils/in-iframe";
import { useQueryStringValue } from "utils/next";
import useIntercom from "utils/useIntercom";
import useQuery from "utils/useQuery";

const signinErrors: Record<string, string> = {
  // Default values copied from next auth
  default: "Unable to sign in.",
  Signin: "Try signing in with a different account.",
  OAuthSignin: "Try signing in with a different account.",
  OAuthCallbackError: "Try signing in with a different account.",
  EmailCreateAccount: "Try signing in with a different account.",
  Callback: "Try signing in with a different account.",
  OAuthAccountNotLinked:
    "To confirm your identity, sign in with the same account you used originally.",
  EmailSignin: "The e-mail could not be sent.",
  CredentialsSignin:
    "Sign in failed. Check the details you provided are correct.",
  SessionRequired: "Please sign in to access this page.",
  // Modified values based on our own application logic
  OAuthCreateAccount: "No organization exists for the account.", // In general will happen when domain mismatched
  Verification:
    "It looks like that link has expired. Please obtain a new link and try again.",
};

const CURRENT_USER_PRISMA = gql(`
query CheckLoggedInLogin {
    currentUser {
      id
    }
  }
`);

function LoginPage() {
  const router = useRouter();
  const returnTo = useQueryStringValue("returnTo");
  const errorCode = useQueryStringValue("error_code");
  const nextAuthErrorParam = useQueryStringValue("error");
  const { sendToast } = useToast();
  const { callIntercom } = useIntercom();

  const { data, refetch } = useQuery(CURRENT_USER_PRISMA, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    callIntercom("update", { hide_default_launcher: false });
  }, [callIntercom]);

  useAcrossTabsListener({
    channel: LOGIN_COMMUNICATION_CHANNEL,
    listener: () => refetch(),
  });

  useEffect(() => {
    if (data?.currentUser?.id) {
      // Prevent redirection to an arbitrary external site
      if (returnTo && returnTo.startsWith("/")) {
        router.push(returnTo);
      } else if (!inIframe()) {
        router.push("/");
      } else {
        window.top?.location.reload();
        // window.location.reload();
      }
    }
  }, [data?.currentUser?.id, returnTo, router]);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (nextAuthErrorParam) {
      const errorMessage =
        signinErrors[nextAuthErrorParam] || signinErrors.default;
      sendToast(errorMessage, {
        variant: "error",
      });
    } else if (errorCode) {
      let msg = "Something went wrong signing up for Guide";
      switch (errorCode) {
        case ErrorCodes.INACTIVE_USER:
          msg =
            "Your account has been deactivated. Please contact your admin to reactivate your account.";
          break;
        case ErrorCodes.TRIAL_ENDED:
          msg =
            "Your account trial has ended. Please contact sales to reactivate your account.";
          break;
        default:
      }
      sendToast(msg, {
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, nextAuthErrorParam]);

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <div className="flex bg-white">
        <div
          className={clsx(
            "flex flex-col w-screen",
            !inIframe() && "lg:block lg:w-1/2"
          )}
        >
          <div className="flex flex-col text-center min-[26.25rem]:flex-row justify-between p-4 z-10">
            <div className="flex flex-row items-center space-x-2">
              <Image src={guideLogoDark} alt="Guide Logo" />
              <div className="flex h-5 px-1.5 justify-center items-center rounded bg-purple-500 text-white text-body-sm-heavy">
                2.0
              </div>
            </div>
            <div className="py-1.5">
              <span className="text-body-md">New to Guide?</span>
              &nbsp;
              <NextLink href="/signup" legacyBehavior>
                <DeprecatedLink>Sign up now</DeprecatedLink>
              </NextLink>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center h-screen -mt-16">
            <div className="mx-4 max-w-[22.25rem]">
              <div className="mb-6">
                <h1 className="text-h2 mb-4">Sign in to Guide</h1>
                <p className="text-body-lg">
                  Welcome to Guide, we&apos;re happy you&apos;re here! To get
                  started, just sign in 👇
                </p>
              </div>
              <div className="space-y-3">
                <LoginForm
                  onLogin={() => {
                    postMessageAcrossTabs(
                      LOGIN_COMMUNICATION_CHANNEL,
                      new Date().toISOString()
                    );
                    refetch();
                  }}
                />
                {nextAuthErrorParam && (
                  <div className="text-red-500">
                    {signinErrors[nextAuthErrorParam] || signinErrors.default}
                  </div>
                )}
              </div>
              {process.env.NODE_ENV === "development" && <DevLoginSection />}
            </div>
          </div>
        </div>
        <div
          className={clsx(
            "hidden bg-light-gray-200",
            !inIframe() && "lg:block lg:w-1/2"
          )}
        >
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="">
              <Image
                src={heroIllustration}
                layout="fixed"
                width={528}
                height={528}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
