import urljoin from "url-join";

const HEIGHT = 600;
const WIDTH = 450;

const openPopUp = (
  queryParams?: Record<string, string>,
  onClose?: () => void
) => {
  const encoded = new URLSearchParams(queryParams).toString();
  const querystring = encoded ? `&${encoded}` : "";
  const top = window.outerHeight / 2 + window.screenY - HEIGHT / 2;
  const left = window.outerWidth / 2 + window.screenX - WIDTH / 2;
  const url = urljoin(
    process.env.NEXT_PUBLIC_NEXTJS_APP_ROOT || "",
    `/login-popup?returnTo=/close${querystring}`
  );
  const popup = window.open(
    url,
    "",
    `height=${HEIGHT},width=${WIDTH},top=${top},left=${left}`
  );

  const interval = setInterval(() => {
    if (popup?.closed) {
      clearInterval(interval);
      onClose?.();
    }
  }, 500);
};

export default openPopUp;
